import { aspectRatio } from '@helpers/aspectRatio';
import brand from '@helpers/brand';
import { ThemeSwitch } from '@helpers/theme';
import styled from 'styled-components';
const InfoWindow = styled.div `
  background: ${brand.white};
  padding: 15px;
  min-width: 200px;
  max-width: 400px;

  h1 {
    margin-bottom: 10px;
    font-size: 16px;
  }

  p {
    font-size: 14px;
  }

  a {
    font-size: 14px;
    ${ThemeSwitch({ groundforceColour: brand.primary.alt })};
    color: var(--theme);
  }
`;
const Holder = styled.div `
  height: 0px;
  width: 100%;
  padding-bottom: ${aspectRatio(943, 448)}%;
  position: relative;
`;
export const MapStyles = {
    InfoWindow,
    Holder,
};
