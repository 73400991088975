import brand from '@helpers/brand';
import { ButtonReset } from '@helpers/global';
import { Device, from } from '@helpers/media';
import { ThemeSwitch } from '@helpers/theme';
import styled from 'styled-components';
const Wrapper = styled.div `
  ${ThemeSwitch({
    groundforceColour: brand.primary.dark,
    groundforcePseudoColourAlt: brand.primary.alt,
    tpaColour: brand.tpa.base,
    tpaPseudoColourAlt: brand.tpa.light,
})}
  background-color: var(--theme);
  padding: 40px 0px;
  position: relative;

  @media ${from(Device.Tablet)} {
    padding: 60px 0px;
  }

  @media ${from(Device.TabletLarge)} {
    padding: 80px 0px;
  }

  @media ${from(Device.Desktop)} {
    padding: 100px 0px;
  }

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--theme-alt);
    bottom: 0;
    left: 0;
    clip-path: polygon(0 85%, 100% 30%, 100% 100%, 0% 100%);
  }
`;
const ContentArea = styled.div `
  grid-column: span 12;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 20px;
  position: relative;
  z-index: 1;

  @media ${from(Device.TabletLarge)} {
    grid-column: 2 / span 4;
  }

  h1 {
    margin-bottom: 30px;
  }

  p,
  a {
    margin-bottom: 24px;
  }

  h1,
  p,
  a {
    color: ${brand.white};
  }
`;
const MapArea = styled.div `
  grid-column: span 12;
  position: relative;
  z-index: 1;

  @media ${from(Device.TabletLarge)} {
    grid-column: span 7;
  }
`;
const Background = styled.img `
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.1;
`;
const Link = styled.a `
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
  text-decoration: none;
  text-underline-position: under;

  &:hover {
    color: ${brand.white};
    text-decoration: underline;
  }

  svg {
    stroke: ${brand.white};
    transform: rotate(90deg);
    height: 10px;
    width: 15px;
    stroke-width: 3px;
  }
`;
const SearchWrapper = styled.div `
  display: flex;
  width: 100%;

  input {
    height: 100%;
    font-size: 18px;
    padding: 10px;
    border: 1px solid #b9b9b9;
    max-width: 340px;
    width: 100%;
  }

  button {
    ${ButtonReset};
    padding: 10px;
    ${ThemeSwitch({ groundforceColour: brand.primary.alt, tpaColour: brand.tpa.light })};
    background-color: var(--theme);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: ${brand.white};
  }

  svg {
    width: 25px;
    height: 25px;
    fill: ${brand.white};
  }
`;
export const DepotLocatorStyles = {
    Wrapper,
    ContentArea,
    MapArea,
    Link,
    SearchWrapper,
    Background,
};
