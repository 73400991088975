import React, { useEffect, useState } from 'react';
import { GoogleMap, useJsApiLoader, InfoWindow, Marker } from '@react-google-maps/api';
import brand from '@helpers/brand';
import { MapStyles } from './Map.styles';
const Map = (props) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: props.googleMapsApiKey,
    });
    const [map, setMap] = useState(null);
    const refitMapIntoMarkers = () => {
        if (!isLoaded || !map) {
            return;
        }
        const bounds = new google.maps.LatLngBounds();
        for (let i = 0; i < props.markers.length; i++) {
            bounds.extend(props.markers[i]);
        }
        map.fitBounds(bounds, 30);
    };
    useEffect(() => {
        if (!isLoaded || !map) {
            return;
        }
        setActiveIndex(-1);
        refitMapIntoMarkers();
    }, [isLoaded, map, props.markers]);
    if (!isLoaded) {
        return React.createElement(MapStyles.Holder, null);
    }
    const containerStyle = {
        position: 'absolute',
        top: '0px',
        left: '0px',
        width: '100%',
        height: '100%',
    };
    const icon = {
        path: 'M35.415,0A12.1,12.1,0,0,0,23.333,12.082c0,5.3,7.551,18.161,10.8,23.445a1.51,1.51,0,0,0,2.573,0c3.244-5.281,10.8-18.137,10.8-23.444A12.1,12.1,0,0,0,35.415,0Zm0,17.368A5.286,5.286,0,1,1,40.7,12.082a5.286,5.286,0,0,1-5.286,5.286Z',
        fillColor: props.theme === 'tpa' ? brand.tpa.light : brand.primary.alt,
        fillOpacity: 1,
        anchor: new google.maps.Point(36, 36),
        strokeWeight: 0,
        scale: 1,
    };
    return (React.createElement(MapStyles.Holder, null, isLoaded ? (React.createElement(GoogleMap, { mapContainerStyle: containerStyle, center: props.center, zoom: props.zoom, onLoad: (m) => setMap(m), options: { maxZoom: 12 } },
        React.createElement(React.Fragment, null, props.markers.map((x, i) => (React.createElement(Marker, { key: x.title + x.link, position: { lat: x.lat, lng: x.lng }, icon: icon, onClick: () => setActiveIndex(i) }, i === activeIndex ? (React.createElement(InfoWindow, null,
            React.createElement(MapStyles.InfoWindow, null,
                React.createElement("h1", null, x.title),
                React.createElement("p", null,
                    x.addressLine1 ? (React.createElement(React.Fragment, null,
                        x.addressLine1,
                        React.createElement("br", null))) : null,
                    x.addressLine2 ? (React.createElement(React.Fragment, null,
                        x.addressLine2,
                        React.createElement("br", null))) : null,
                    x.city ? (React.createElement(React.Fragment, null,
                        x.city,
                        React.createElement("br", null))) : null,
                    x.postcode ? (React.createElement(React.Fragment, null,
                        x.postcode,
                        React.createElement("br", null))) : null,
                    x.county ? (React.createElement(React.Fragment, null,
                        x.county,
                        React.createElement("br", null))) : null),
                React.createElement("p", null,
                    x.phoneNumber ? (React.createElement(React.Fragment, null,
                        React.createElement("a", { href: `tel:${x.phoneNumber}` }, x.phoneNumber),
                        React.createElement("br", null))) : null,
                    x.emailAddress ? (React.createElement(React.Fragment, null,
                        React.createElement("a", { href: `mailto:${x.emailAddress}` }, x.emailAddress),
                        React.createElement("br", null))) : null),
                x.link ? React.createElement("a", { href: x.link }, "View Here") : null))) : null)))))) : null));
};
export default Map;
